<template>
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">{{ $t('title.BasicInformation') }}</el-col>
    </el-row>
    <el-form label-width="100px">
      <div>
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="SKU" prop="outboundCode">
              {{ basicInfor.sku }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="Style" prop="outboundCode">
              {{ basicInfor.Style }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="Color" prop="outboundCode">
              {{ basicInfor.color }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="Size" prop="outboundCode">
              {{ basicInfor.size }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="总库存">
              {{ basicInfor.totalQuantity }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="计划库存">
              {{ basicInfor.planQuantity }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="采购在途库存">
              {{ basicInfor.purchaseIntransitQuantity }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="发货在途库存">
              {{ basicInfor.intransitQuantity }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="在库库存">
              {{ basicInfor.inStockQuantity }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="可用库存">
              {{ basicInfor.availableQuantity }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="锁定库存">
              {{ basicInfor.lockQuantity }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="待出库库存">
              {{ basicInfor.awaitOutQuantity }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="不良品库存">
              {{ basicInfor.brokenQuantity }}</el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <el-row class="grid-content bg-purple-dark" type="flex">
      <span class="bg-purple-title">{{ $t('title.InventoryDetails') }}</span>
    </el-row>
    <el-form ref="addOutboundForm" :model="form" label-width="100px">
      <div class="el-lt" style="width:100%;">
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item :class="$i18n.locale" label="逻辑仓" prop="outboundCode">
              <el-select
                v-model="form.logicWarehouseCode"
                clearable
                :placeholder="$t('page.selectPlaceholder')"
                @change="_getCargoOwnerDetail(1)"
              >
                <el-option
                  v-for="item in warehouseList"
                  :key="item.warehouseCode"
                  :value="item.logicWarehouseCode"
                  :label="item.logicWarehouseName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="货主" prop="outboundCode">
              <el-select
                v-model="form.cargoOwnerCode"
                filterable
                clearable
                placeholder="请选择"
                @change="_getCargoOwnerDetail(1)"
              >
                <el-option
                  v-for="item in cargoOwnerList"
                  :key="item.logicWarehouseCode"
                  :value="item.cargoOwnerCode"
                  :label="item.cargoOwnerName"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <el-table
      ref="tableData"
      v-loading="tableLoading"
      class="mb-3"
      :data="tableData"
      show-summary
      :header-cell-style="{background:'#fafafa'}"
      max-height="550px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="logicWarehouseName" label="逻辑仓库" align="center" />
      <el-table-column prop="cargoOwnerCode" label="货主" align="center" />
      <el-table-column prop="totalQuantity" label="总库存" align="center" />
      <el-table-column prop="planQuantity" label="计划库存" align="center" />
      <el-table-column prop="purchaseIntransitQuantity" label="采购在途库存" align="center" />
      <el-table-column prop="intransitQuantity" label="发货在途库存" align="center">
        <template slot-scope="scope"><router-link style="color:#1890ff;" :to="{ path: `/inventory-manage/inventory-inquiry/sku-dimension/sku-purchase-delivery?sku=${basicInfor.sku}&row=${JSON.stringify(scope.row)}`}">{{ scope.row.intransitQuantity }}</router-link></template>
      </el-table-column>
      <el-table-column prop="inStockQuantity" label="在库库存" align="center" />
      <el-table-column prop="availableQuantity" label="可用库存" align="center">
        <template slot-scope="scope">
          <router-link style="color:#1890ff;" :to="{ path: `/inventory-manage/inventory-inquiry/sku-dimension/sku-available-inventory?sku=${basicInfor.sku}&row=${JSON.stringify(scope.row)}`}">{{ scope.row.availableQuantity }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="lockQuantity" label="锁定库存" align="center">
        <template slot-scope="scope">
          <router-link style="color:#1890ff;" :to="{ path: `/inventory-manage/inventory-inquiry/sku-dimension/sku-lock-inventory?sku=${basicInfor.sku}&row=${JSON.stringify(scope.row)}`}">{{ scope.row.lockQuantity }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="awaitOutQuantity" label="待出库库存" align="center">
        <template slot-scope="scope">
          <router-link style="color:#1890ff;" :to="{ path: `/inventory-manage/inventory-inquiry/sku-dimension/sku-outgoing-inventory?sku=${basicInfor.sku}&row=${JSON.stringify(scope.row)}`}">{{ scope.row.awaitOutQuantity }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="brokenQuantity" label="不良品库存" align="center" />
    </el-table>
    <el-row class="grid-content bg-blue" type="flex" justify="center">
      <el-button @click="cancle()">{{ $t('title.back') }}</el-button>
    </el-row>
  </div>
</template>
<script>

import { getSkuDetail } from '@/api/inventory-inquiry'
import { getWarehouse } from '@/api/stockin'
import { getCargoOwner } from '@/api/listSelection'

export default {
  data() {
    return {
      basicInfor: {},
      warehouseList: [],
      tableLoading: false,
      form: {
        logicWarehouseCode: '',
        cargoOwnerCode: ''
      },
      cargoOwnerList: [],
      tableData: [],
      pager: {
        size: 100,
        current: 1,
        total: 0
      }
    }
  },
  computed: {
    queryParams() {
      const { sku } = this.$route.query
      return Object.assign({}, this.pager, this.form, { sku })
    }
  },
  mounted() {
    this._getCargoOwnerDetail()
    this._queryWarehouseList()
    this._getCargoOwner()

    const { row } = this.$route.query
    this.basicInfor = row && JSON.parse(row) || {}
  },
  methods: {
    async _getCargoOwnerDetail(flag) {
      try {
        this.tableLoading = true
        flag && flag === 1 ? this.pager.current = 1 : ''
        const { datas: { pager, records }} = await getSkuDetail(this.queryParams)
        this.pager = pager
        this.tableData = records
      } finally {
        this.tableLoading = false
      }
    },
    async _queryWarehouseList() {
      const { datas } = await getWarehouse()
      this.warehouseList = datas
    },
    cancle() {
      this.$router.go(-1)
    },
    async _getCargoOwner() {
      const { datas } = await getCargoOwner()
      this.cargoOwnerList = datas
    }
  }
}
</script>
<style lang="scss" scope>
.bg-purple-dark {
  border: 1px solid #bae7ff;
  background: #e6f7ff;
  color: #1890ff;
  size: 14px;
  padding: 6px;
  margin-bottom: 10px;
  .bg-purple-title {
    line-height: 36px;
    text-indent: 20px;
  }
}
.float-left {
  float: left;
}
</style>
